.carousel .slide img {
    width: 100%;
    height: 200px; /* Reduced height for mobile view */
    object-fit: cover;
}

/* @media (min-width: 768px) {
    .carousel .slide img {
        max-height: 500px; 
    }
} */


.carousel-caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    text-align: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.3); /* Optional: Adds slight overlay for readability */
}

.carousel-caption h2 {
    font-size: 2.5em; /* Larger size for desktop */
    font-weight: bold;
    margin: 0;
}

.carousel-caption p {
    font-size: 1.2em; /* Adjust size as needed */
    margin-top: 5px;
}

@media (min-width: 768px) {
    .carousel-caption h2 {
        font-size: 3em; /* Increase font size for larger screens */
    }

    .carousel-caption p {
        font-size: 1.5em; /* Increase font size for larger screens */
    }
}
